import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'

const renderType = (type, title, link) => {
  return (
    <div>
      <h2 className='text-2xl'>{title}</h2>
      <ul>
        {type.nodes.map((item, index) => (
          <li key={index}>
            <Link to={item.fields.slug}>{item.frontmatter.title}</Link>
          </li>
        ))}
      </ul>
      <Link to={link}>Vezi mai multe {title}</Link>
    </div>
  )
}

const Cazare = ({ data }) => {
  return (
    <Layout>
      <div style={{ backgroundImage: 'url(https://dimg02.c-ctrip.com/images/10031e000001fq0hcB1E3.jpg_.webp)' }}>
        <div className='container text-center'>
          <h1 className="text-4xl text-white py-6">Cazare</h1>
        </div>
      </div>
      <div className='container'>
        {/* {renderRecentlyViewed()} */}
      </div>
      <div className='container'>
        {renderType(data.Cabana, 'Cabane', '/cazare/cabana/')}
        {renderType(data.Casute, 'Casute', '/cazare/casute/')}
        {renderType(data.Casa, 'Case/Vile', '/cazare/casa/')}
        {renderType(data.Pensiune, 'Pensiune', '/cazare/pensiune/')}
      </div>
    </Layout>
  )
}

export const query = graphql`
{
  Cabana: allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}, type: {eq: "Cabana"}}}) {
    nodes {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
  Casute: allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}, type: {eq: "Casute"}}}) {
    nodes {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
  Casa: allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}, type: {eq: "Casa/Vila"}}}) {
    nodes {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
  Pensiune: allMarkdownRemark(filter: {frontmatter: {featured: {eq: true}, type: {eq: "Pensiune"}}}) {
    nodes {
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
}
`

export default Cazare;